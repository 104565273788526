import axios from "axios";
import { config } from "./config";
import { ResponseStatusEnum } from "@/enums/httpEnum";

const axiosInstance = axios.create(config)


axiosInstance.interceptors.request.use(config => {
  return config
}, error => {
  console.error("request error:", error)
})

axiosInstance.interceptors.response.use((response) => {
  if(response.data.code === ResponseStatusEnum.SUCCESS){
    return response
  }
  return response
}, (error) => {
  console.error("error:", error)
})

export {axiosInstance}