<template>
  <router-view />
</template>

<script setup>
  import { onMounted } from "vue"
  import { useLangStore } from "@/store/language"
  import WOW from "wow.js"


  onMounted(() => {
    new WOW().init()
    const langStore = useLangStore()
    langStore.updateLang()
  })
</script>
