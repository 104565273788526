import { defineStore } from 'pinia'
import { computed, ref, reactive } from 'vue'
import { getArticle } from '@/api/article';

export const useArticleStore = defineStore("article", () => {
    const articles = ref([])

    function initArticle(lang){
      getArticle(lang).then(res => {
        if(res?.data && res.data.length > 0){
          articles.value = res.data
        }
      })
    }

    return {
      articles,
      initArticle
    }
})