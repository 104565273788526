import { defineStore } from 'pinia'
import { computed, ref, reactive } from 'vue'
import { languageList } from '@/api/language';
import { useArticleStore } from "@/store/article"

export const useLangStore = defineStore("language", () => {
  const langArray = ref([])
  const langObj = ref({})
  const currentLang = ref("jp")

  const languages = reactive([
    {
      flag: require("@/assets/img/flags/japan.png"),
      language: "jp",
      title: "日本語"
    },
    {
      flag: require("@/assets/img/flags/us.jpg"),
      language: "en",
      title: "English"
    },
    {
      flag: require("@/assets/img/flags/china.png"),
      language: "cn",
      title: "中文（简体）"
    }
  ])

  function setLang(lang){
    currentLang.value = lang
    const articleStore = useArticleStore()
    articleStore.initArticle(lang)
  }

  function updateLang(){
    languageList().then(res => {
      if(res?.data && res.data.length > 0){
        langArray.value = res.data
        const obj = {}
        res.data.forEach(item => {
          obj[item.key] = {
            en:item['en'],
            jp:item['jp'],
            cn:item['cn']
          }
        })
        langObj.value = obj
      }
    })

    const articleStore = useArticleStore()
    articleStore.initArticle(currentLang.value)
  }

  const t = computed(() => (key) => {
    const lang = langObj.value[key]
    if(lang){
      return lang[currentLang.value] || key
    }
    return key
  })

  const lang = computed(() => currentLang.value)

  return {langArray, langObj,  currentLang, setLang, updateLang, t, lang, languages}
},{
  persist: true
})