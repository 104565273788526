import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import '../src/assets/css/fontawesome-all.min.css'
import 'animate.css/animate.min.css'
import '../src/assets/scss/style.scss'
import 'swiper/css';
import 'swiper/css/effect-fade';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const toastOption = {
  transition: "Vue-Toastification__fade",
  maxToasts: 5,
  newestOnTop: true,
  position: "bottom-center",
  hideProgressBar: true,
  timeout: 3000,
  draggable: false,
  closeOnClick: false,
}

createApp(App)
.use(pinia)
.use(Toast,toastOption)
.use(router)
.mount("#app");
